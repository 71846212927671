import {
  Float,
  Environment,
  useGLTF,
  OrbitControls,
  PresentationControls,
  ContactShadows,
  Html,
  Text,
} from "@react-three/drei";

export default function Experience() {
  const computer = useGLTF(
    "https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/macbook/model.gltf"
  );
  const coffee = useGLTF(
    "https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/spilling-coffee/model.gltf"
  );

  const rightHand = useGLTF(
    "https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/skeleton-right-hand-webxr/model.gltf"
  );

  const iphone = useGLTF(
    "https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/iphone-x/model.gltf"
  );

  return (
    <>
      <Environment preset="city" />
      <color args={["#241a1a"]} attach="background" />
      <OrbitControls makeDefault />

      <PresentationControls
        enabled={true}
        global
        rotation={[0.13, 0.1, 0]}
        polar={[-0.4, 0.2]}
        azimuth={[-1, 0.75]}
        config={{ mass: 2, tension: 400 }}
        snap={{ mass: 4, tension: 400 }}
      >
        <Float rotationIntensity={0.4}>
          <rectAreaLight
            width={2.5}
            height={1.65}
            intensity={65}
            color={"#ff6900"}
            rotation={[-0.1, Math.PI, 0]}
            position={[0, 0.55, -1.15]}
          />
          <primitive object={coffee.scene} position-y={0} position-x={-3}>
            <primitive
              object={rightHand.scene}
              position-y={0}
              position-z={1}
              position-x={2}
              scale={6}
              rotation={[-5, 0, 7]}
            />
          </primitive>
          <primitive object={computer.scene} position-y={-1.2}>
            <Html
              transform
              wrapperClass="htmlScreen"
              distanceFactor={1.17}
              position={[0, 1.56, -1.4]}
              rotation-x={-0.256}
            >
              <iframe src="https://somedose.com/" />
            </Html>
          </primitive>
          <primitive
            object={iphone.scene}
            position-y={-1}
            position-z={2.5}
            position-x={-3}
            rotation-x={90}
            scale={-0.5}
          ></primitive>
          <Text
            font="./Megabot Five.otf"
            fontSize={0.8}
            position={[2, 0.75, 0.075]}
            rotation-y={-1.25}
            maxWidth={2}
            textAlign="center"
          >
            Sonia Grant
          </Text>
        </Float>
      </PresentationControls>
      <ContactShadows position-y={-1.4} opacity={0.4} scale={5} blur={2.4} />
    </>
  );
}
